.service_content {
    padding: 9rem 8rem 4rem 8rem;
}

.service_content img {
    height: 25rem;
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1080px) {
    .service_content {
        padding: 7rem 1rem 4rem 1rem;
    }
}
