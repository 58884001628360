.home_hero {
    background: url(https://res.cloudinary.com/emacon-production/image/upload/v1651469871/Vienna%20Technologies%20LTD/CF028262_vx1cub.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.home_hero_content {
    padding: 15rem 15rem 15rem 15rem;
    color: white;
    background: rgba(0, 0, 0, 0.2);
}

.home_hero_content h1 {
    font-weight: 600;
    margin-top: 2rem;
}
.home_hero_content button {
    padding: 1rem 2rem;
    border-radius: 35px;
    margin-top: 2rem;

    background: #fb0009;
    border: none;
    color: white;
}

.home_hero_content button:hover {
    background: #fa4e54;
}

.home_contentData {
    padding: 2rem 8rem 0rem 8rem;
}

.home_contentData_title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.home_contentData_title i {
    font-size: 6rem;
    color: #fb0009;
}

.home_contentData_title h2 {
    font-weight: 600;
    font-size: 35px;
}

.home_contentData_banner {
    background: url(https://res.cloudinary.com/emacon-production/image/upload/v1651469766/Vienna%20Technologies%20LTD/CF028073_zpfu9h.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 3rem 0rem;
}

.home_contentData_banner_content {
    padding: 5rem 15rem 5rem 15rem;
    color: white;
    background: rgba(0, 0, 0, 0.5);
}

.home_contentData_banner_content button {
    padding: 0.8rem 1.5rem;
    border-radius: 35px;
    margin-top: 2rem;

    background: #fb0009;
    border: none;
    color: white;
}

.home_contentData_banner_content button:hover {
    background: #fa4e54;
}

.maintenancePrograms_thumbnail {
    display: flex;
    justify-content: center;
}

.maintenancePrograms_thumbnail img {
    height: 8rem;
}

.carousel {
    z-index: 0;
}

.carousel__background1 {
    background: url(https://res.cloudinary.com/emacon-production/image/upload/v1658221031/Vienna%20Technologies%20LTD/abhijeet-gaikwad-wUuq69GGLnU-unsplash_b1ntt7.jpg);
    background-size: cover;
    background-position: center;
    /* height: 32rem; */
    width: 100%;
    transition: all .4s;
}

.carousel__background2 {
    background: url(https://res.cloudinary.com/emacon-production/image/upload/v1658221032/Vienna%20Technologies%20LTD/emmanuel-ikwuegbu--0-kl1BjvFc-unsplash_t6ajck.jpg);
    background-size: cover;
    background-position: center;
    /* height: 32rem; */
    width: 100%;
    transition: all .4s;
}

.carousel__background3 {
    background: url(https://res.cloudinary.com/emacon-production/image/upload/v1658221030/Vienna%20Technologies%20LTD/chelsea-WvusC5M-TM8-unsplash_u6on5x.jpg);
    background-size: cover;
    background-position: center;
    /* height: 32rem; */
    width: 100%;
    transition: all .4s;
}

.carousel__background4 {
    background: url(https://res.cloudinary.com/emacon-production/image/upload/v1652894324/Vienna%20Technologies%20LTD/istockphoto-1284506686-170667a_ed62ny.jpg);
    background-size: cover;
    background-position: center;
    /* height: 32rem; */
    width: 100%;
    transition: all .4s;
}

.black_wrapper {
    height: 100%;;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}


@media screen and (max-width: 1080px) {
    .home_hero_content {
        padding: 10rem 5rem 10rem 5rem;
    }

    .home_contentData {
        padding: 2rem 3rem 0rem 3rem;
    }

    .home_contentData_banner_content {
        padding: 5rem 3rem 5rem 3rem;
    }
}

@media screen and (max-width: 650px) {
    .home_hero_content {
        padding: 10rem 1rem 10rem 1rem;
    }

    .home_contentData {
        padding: 2rem 2rem 0rem 2rem;
    }

    .home_contentData_title {
        margin-left: -2rem;
    }

    .home_contentData_title i {
        font-size: 5rem;
    }
    
    .home_contentData_title h2 {
        font-size: 32px;
    }

    .home_contentData_banner_content {
        padding: 5rem 1rem 5rem 1rem;
    }
}