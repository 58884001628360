.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
    background: #fff;
    width: 100%;
    z-index: 1;
}

.navbar_companyLogo {
    padding: 0rem 2rem;
    cursor: pointer;
}

.navbar_companyLogo img {
    height: 6rem;
    object-fit: contain;
}

.navbar_menuLinkWrapper {
    padding: 0rem 3rem 0rem 0rem;
}

.navbar_menuLinks {
    color: rgb(68, 68, 68);
    text-decoration: none;
    font-weight: 500;
    padding: 0rem 2rem 0rem 0rem;

    cursor: pointer;

    transition: all .3s;
}

.navbar_menuLinks:hover {
    color: #fb0009 !important;
}

.mobileMenu_btn {
    display: none;
}

.mobileMenu_content {
    display: none;
}

/* @media screen and (max-width: 1080px) {} */

@media screen and (max-width: 650px) {
    .navbar_companyLogo {
        padding: 0rem 1rem;
    }

    .navbar_menuLinkWrapper{
        display: none;
    }

    .mobileMenu_btn {
        display: flex;
        padding: 0rem 1rem 0rem 0rem;

        cursor: pointer;
    }

    .mobileMenu_btn i {
        font-size: 3rem;
    }

    .mobileMenu_content {
        display: flex;
        flex-direction: column;
        position: fixed;
        overflow-y: auto;
        right: -500px;
        top: 0;
        width: 300px;
        height: 100vh;
        padding: 20px;
        z-index: 10000;
        background-color: #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        transition: right .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    
    .mobileMenu_content.active {
        right: 0;
    }
    
    .mobileMenu_closebtn {
        position: absolute;
        top: 17px;
        right: 20px;
        background-color: transparent;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .mobileMenu_linksWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100% !important;
    }

    .mobileMenu_inks {
        font-size: 22px;
        font-weight: 500;
        color: black;
        text-decoration: none;
        padding: 2rem 0rem;
    }
}

/* @media screen and (max-width: 490px) {} */