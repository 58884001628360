.contact_us_content {
    padding: 8rem 8rem 8rem 8rem;
}

.contact_us_content form {
    display: flex;
    flex-direction: column;
}

.contact_us_content form input {
    /* width: 50vw; */

    margin: 0.5rem 0rem;
    padding: 0.4rem;

    border: 1px solid rgb(190, 190, 190);

    transition: all .3s;
}

.contact_us_content form input:focus {
    border: 1px solid #e0a041 !important;
}

.input_labels {
    display: flex;
    align-items: center;

    margin-top: 1rem;
}

.input_labels span {
    color: red;
    margin-left: 1rem;
    font-size: 13px;
}

.contact_us_content form button {
    padding: 0.8rem 2rem;
    /* width: fit-content; */
    margin: 1rem 0rem 0rem 0rem;

    border: none;
    background: #fb0009;
    color: #fff;

    cursor: pointer;

    transition: all .3s;
}

.contact_us_content form button:hover {
    background: #fa4e54;
}

/* .company_details_wrapper {
    margin-left: 0.2rem
} */

/* .company_details {
    display: flex;
    align-items: center;
} */

@media screen and (max-width: 1080px) {
    .contact_us_content {
        padding: 8rem 1rem 8rem 1rem;
    }
    
}

@media screen and (max-width: 650px) {
    .company_details_wrapper {
        margin-top: 3rem;
    }
}

