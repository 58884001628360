.footer {
    background: black;
    color: #fff;
    padding: 3rem 3rem;
}

.footer_section1 {
    display: flex;
    justify-content: space-between;
}

.footer_links {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    padding: 0rem 2rem 0rem 0rem;

    cursor: pointer;

    transition: all .3s;
}

.footer_links i {
    font-size: 1.3rem;
}

.footer_links:hover {
    color: #fb0009;
}

.footer_socials {
    color: #fff;
}

.footer_section3 {
    margin-top: 4rem;
}

.footer_copyrightlinks {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    border-bottom: #fff solid 1px;

    cursor: pointer;

    transition: all .3s;
}

.footer_copyrightlinks:hover {
    color: #fb0009;
    border-bottom: #fb0009 solid 1px;
}

/* @media screen and (max-width: 1080px) {} */

@media screen and (max-width: 650px) {
    .footer_section1 {
        flex-direction: column;
    }

    .footer_menu {
        display: flex;
        flex-direction: column;

        padding: 0rem 0rem 4rem 0rem;
    }

    .footer_links {
        padding: 1rem 0rem 0rem 0rem;
    }

    .footer_links i {
        font-size: 2rem;
        padding: 0rem 2rem 0rem 0rem ;
    }
}

/* @media screen and (max-width: 490px) {} */