.about_content {
    padding: 6rem 0rem 0rem 0rem;
}

.about_content_section1 {
    background: url(https://res.cloudinary.com/emacon-production/image/upload/v1651469871/Vienna%20Technologies%20LTD/CF028262_vx1cub.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}

.about_section1_bg {
    position: absolute;
    height: 100%;;
    width: 100%;
    background: black;
    opacity: 0.2;
}

.about_section1_title {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5rem 0rem;

    color: white;
    font-weight: 600;
}

.about_section1_title h1 {
    font-weight: 600;
    z-index: 1;
}

.about_content_section2 {
    padding: 2rem 8rem 0rem 8rem;
}

.about_content_section2 h3 {
    color: #fb0009;
    font-weight: 500;
}

@media screen and (max-width: 1080px) {
    .about_content_section2 {
        padding: 1rem 3rem 0rem 3rem;
    }
}

@media screen and (max-width: 650px) {
    .about_content_section2 {
        padding: 1rem 1rem 0rem 1rem;
    }
    
}