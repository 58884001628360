.serviceCard {
    position: relative;
    height: 280px;
    background: #ffffff;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 5rem;
    border: none;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    /* z-index: 1000; */
}
.serviceCard .imgBx {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 0.8rem; */
    /* z-index: 1; */
}

/* .card .imgBx > img {
    max-width: 100%;
    height: 14rem;
    width: 10rem;
    object-fit: cover;
    transition: 0.5s;
} */

.serviceCard .imgBx > img {
    max-width: 100%;
    height: 10rem;
    width: 100%;
    object-fit: cover;
    transition: 0.5s;
}

.serviceCard:hover .imgBx > img {
    height: 8rem;
    object-fit: cover;
}

.serviceCard .contentBx {
    position: relative;
    padding: 3px 0px 0px 0px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* z-index: 1; */
}

.serviceCard .contentBx > p {
    font-size: 12px;
    text-align: center;
    color: black;
    letter-spacing: 1px ;
}

.serviceCard .contentBx .learnBtn {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* top: 120px; */
    opacity: 0;
    margin-top: 8px;
    transition: 0.5s;
}

/* .serviceCard .contentBx .learnBtn > p {
    color: black;
    font-size: 20px;
    padding: 0rem 2rem;
    margin-top: 1rem;
}

.serviceCard:hover .contentBx .learnBtn {
    top: 1;
    opacity: 1;
} */

.learnBtn_link {
    text-decoration: none;
    color: #fb0009;
    font-size: 12px;
    transition: all .3s;
}

.learnBtn_link:hover {
    color: #ff7a7e;
}
