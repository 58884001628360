.partners_wrapper {
    padding: 3rem 0rem;
    overflow-x: hidden;
}

.partners {
    padding: 0rem 1rem;
    height: 6rem;
    object-fit: contain;
}

@media screen and (max-width: 650px) {
    .partners_wrapper {
        padding: 2rem 0rem;
    }

    .partners {
        height: 6rem;
    }
}